.help-left-bar>div{
    position: relative !important;
    padding: 0 calc(var(--mdb-gutter-x) * 0.5);
}
.help-types{
    /* background:rgba(90, 205, 240, 0.5) !important; */
    justify-content: center;
    color: #000;
    display: flex;
    min-height: 40px !important;
}
.help-header{
    display: none !important;
}

.activeted{
    color:#212121;
    background: rgba(212, 212, 212, 0.75) !important;
    box-shadow: 0 0 5px 0 rgb(251, 252, 252) !important;
    border-radius: 10px;
    cursor: pointer;
}
.no-activeted{
    background-color: #000;
}
.help-container{
    --mdb-gutter-x:2rem;
    padding-right: 0;
    padding-left: 0;
}
.help-left-menu{
    cursor: pointer;
    border-radius: 10px;
}
.help-left-menu:hover{
    background-color: #e2e2e4;
}
body,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
p,
form,
img {
	border: 0 none;
	vertical-align: middle
}
.doc-main{
    width: 100%;
    padding-left: calc(var(--mdb-gutter-x) * 0.5);
    padding-right: calc(var(--mdb-gutter-x) * 0.5);
}
.doc-main img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.fiexd-left{
    position: fixed !important;
    top: 78px !important;
}
.remove-fiexd{
    position: relative;
    top: 0;
}
.left{
    margin-left: 350px;
}
.nav-style{
    max-height: 610px;
    overflow: hidden;
}
/* 重写系统默认样式，添加自定义，修复新闻滚动闪屏问题 */
/* .css-fu4img{
    max-height: 400px;
    overflow-y: scroll;
} */
/* 默认文章主体字体统一为24px，1.5rem */
.body-content{
    white-space: normal;
    word-break: break-all;
}
.body-content p,.body-content p>font,.body-content p span>font{
    font-size: 1.5rem;
}
@media screen and (max-width: 767px){
    .help-types {
        display: none !important;
    }
    .help-header{
        display: block !important;
    }
    .help-container{
        --mdb-gutter-x:0.5rem;
    }
    .MuiDrawer-paper{
        width: 75% !important;
    }
    .doc-main{
        width: 100%;
        position: relative;
        bottom: 0;
    }
    /* .doc-main img{
        width: 100%;
    } */
    .MuiDrawer-paper{
        width: 350px !important;
    }
    .left{
        margin-left: 0 !important;
    }
}