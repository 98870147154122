.banner-app {
    width: 100%;
    height: 40vh;
    position: relative;
    text-align: center;
    border-color: #666;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.banner-app-elem {
    text-align: center;
    color: #fff;
    position: relative;
    overflow: hidden;
}

.banner-app-elem .banner-app-title {
    font-size: 3rem;
    top: 40%;
}

.banner-app-elem .banner-app-text {
    top: 40%;
}

.banner-anim-elem .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center;
} 
.banner-app-wrapper {
    display: inline-block !important;
    position: absolute !important;
    top: 35% !important;
    margin: auto !important;
    left: 0 !important;
    right: 0 !important;
    font-size: 14px !important;
    color: #e1f5fe;
    width: 550px;
}
.banner-app-title {
    width: auto;
    min-width: 350px;
    /* left: 30px; */
    min-height: 60px;
    margin: auto;
    display: inline-block;
    position: relative;
    color: #fff;
}
.banner-app-title>h3{
    font-size: 2.5rem;
    letter-spacing: 3px;
}
.banner-app-content {
    margin-bottom: 20px;
    margin-top: 20px;
    word-wrap: break-word;
    min-height: 24px;
    color: #fff;
}
.banner-app-content>span{
    font-size: 1.5rem;
    font-weight: normal;
    letter-spacing: 3px;
}
.app-advantage{
    padding: 0 !important;
    min-height: 460px;
    position: relative;
    overflow: hidden;
}
.sub1-col{
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.sub1-card{
    text-align: center;
}
.sub1-img{
    position: top;
    width: 60%;
    padding: 1rem;
    padding-bottom: 0;
}

.app-show{
    padding: 0 !important;
    min-height: 550px;
    position: relative;
    overflow: hidden;
    background-color: rgba(253,253,253,.3);
    background-image: linear-gradient(360deg,rgba(193,218,255,0.3) 0%,rgba(253,253,253,0.3) 80%);
}
.client-download{
    text-align: center;
    padding-top: 0 !important;
}
.tooltip-inner{
    padding: 0;
}
.client-swiper .swiper-slide{
    width: 450px !important;
    height: 450px !important;
}
