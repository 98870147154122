.company-des{
    padding: 0 !important;
    min-height: 470px;
    position: relative;
    overflow: hidden;
}
.aboutus-container{
    height: 100%;
    position: relative;
    margin: auto;
    will-change: transform;
    flex-direction: column;
}
.aboutus-banner-img{
    height: 385px;
}
.culture-title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
}

.culture-title-wrapper {
    margin: 0 auto 48px
}

.culture-title-wrapper>h1 {
    font-size: 1.6rem;
    color: #314659;
}
.swiper-wrapper>div{
    width: 300px !important;
    height: auto !important;
}
