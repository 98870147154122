.video-data-container{
    height: 100%;
    position: relative;
    margin: auto;
    will-change: transform;
    flex-direction: column;
}
.video-banner-img{
    height: 360px;
}
.video-card-img{
    width: 26%;
}
.video-col{
    padding-top: 0 !important;
    padding-bottom: 1.5rem !important;
    text-align: center;
}
.video-card-slide-text{
    text-align: left;
    color: #263238;
    font-size: .9rem;
}
.video-tec{
    padding: 0 !important;
    min-height: 470px;
    position: relative;
    overflow: hidden;
}
.video-map{
    padding: 0 !important;
    min-height: 780px;
    position: relative;
    overflow: hidden;
}
.video-tec-container{
    height: 100%;
    position: relative;
    margin: auto;
    will-change: transform;
    flex-direction: column;
}
.video-card-over-text{
    position: absolute;
    bottom: 16px;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    color: #fff;
    border-radius: 20px;
    width: 85%;
}
.video-row-1{
    margin: auto;
}
.video-row-1>div{
    margin: auto;
}
.video-row-1 .card,.video-row-2 .card,.video-row-3 .card,.video-row-4 .card{
    border-radius: 30px;
    box-shadow: 0 2px 15px -3px rgba(11, 3, 111, 0.5), 0 10px 20px -2px rgba(0, 0, 0, 0.04);
}
.video-row-2,.video-row-3,.video-row-4{
    justify-content: space-between;
}
.video-card{
    border-radius: 20px;
    text-align: center;
}
.videdo-card-map{
    text-align: center;
}
.videdo-card-map-body{
    padding-top: .3rem;
    padding-bottom: .5rem;
}
.videdo-card-map-img{
    width: 30%;
    margin: auto;
}
.videdo-card-map-title{
    font-size: .95rem;
    font-weight: 600;
}
.videdo-card-map-text{
    font-size: .9rem;
}
.video-map-card-img{
    display: block;
}
.video-flow{
    padding: 0 !important;
    min-height: 450px;
    position: relative;
    overflow: hidden;
}
.video-flow-card{
    text-align: center;
    border-radius: 100px;
}
.video-flow-card-img{
    padding: 4rem !important;
    padding-bottom: 0 !important;
    padding-top: 1rem !important;
    border-radius: 0;
}
.video-flow-col{
    padding: 4rem !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.video-use-now{
    text-align: center;
    display: block;
}
.video-flow-col-title >h1{
    font-size: 1.9rem !important;
    text-align: center;
    padding: 1rem;
    background: #e1f5fe;
    transition: border .5s cubic-bezier(0.455, 0.03, 0.515, 0.955), background .5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    box-shadow: 0 8px 24px -2px rgba(0,0,0,.05);
    border-radius: 100px;
}
@media screen and (max-width: 767px){
    .video-card-slide-text {
        font-size: 1.3rem;
    }
    .video-map-card-img{
        display: none;
    }
    .video-flow-card-img{
        padding: 3rem !important;
        padding-bottom: 0 !important;
        padding-top: 1rem !important;
        border-radius: 0;
    }
    .video-flow-col{
        padding: 2rem !important;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .video-flow-card .h5{
        font-size: 1rem !important;
    }
    .video-use-now{
        display: none;
    }
}