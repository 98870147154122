/* 重写导航栏样式 */
.sticky-top {
    position: sticky !important;
    top: 0 !important;
    z-index: 1020 !important;
}

.app-home {
    padding: 0;
}

.diy-navbar {
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: 999 !important;
    border-bottom: 1px solid transparent;
    transition: border .5s cubic-bezier(0.455, 0.03, 0.515, 0.955), background .5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    width: 100%;
    box-shadow: 0 8px 24px -2px rgba(0,0,0,.05);
    position: fixed !important;
    top: 0;
}
.diy-navbar-brand {
    padding: 0;
}

.diy-brand-img {
    width: 60px;
    height: 60px;
}

.nav-item {
    margin: 5px;
}

.diy-brand-img {
    width: 144px;
}

.nav-item .nav-link {
    color: #e0e0e0;
}

.nav-item:hover {
    transition: 1s;
    transform: scale(1.1);
}

.active {
    color: #fff !important;
    border-bottom: 2px solid #1890ff;
}

.nav-item .nav-link {
    display: inline;
    text-align: center;
    position: relative;
}

.nav-item .nav-link::after {
    position: absolute;
    left: 0;
    top: 100%;
    content: '';
    background-color: #1890ff;
    width: 90%;
    transform: scale(0);
    -webkit-transform: scale(0);
    transition: all .5s;
    -webkit-transition: all .5s;
}

.nav-item .nav-link:hover::after {
    height: 2px;
    -webkit-transform: scale(1);
    transform: scale(1);
}
/* 本地测试使用 */
/* .css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab {
    background-color: #b3e5fc !important;
} */
/* 打包编译使用 */
.css-r9uhzn{
    background-color: #b3e5fc !important;
}
.css-wf16b5{
    width: 20%;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    overflow: hidden;
}
.drawer-row{
    --mdb-gutter-x:0;
    padding: 10%;
    text-align: center;
}
.app-qr-right {
    position: fixed;
    bottom: 53% !important;
    right: 1% !important;
}

.scroll-top {
    z-index: 9999;
    position: fixed;
    right: 2%;
    bottom: 15%;
}
/*定义滚动条高宽及背景高宽分别对应横竖滚动条的尺寸*/
 ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0,0,0,.2);
}

/*定义滚动条轨道内阴影+圆角*/
::-webkit-scrollbar-track
{
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);
    border-radius:10px;
    background-color:#F5F5F5;
}
/*定义滑块内阴影+圆角*/
::-webkit-scrollbar-thumb
{
    border-radius:10px;
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.3);
    background-color: #d1c4e9;
}
.app-reg-login {
    display: block;
}
@media screen and (max-width: 767px){
    .app-reg-login {
        display: none !important;
    }
}