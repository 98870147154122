 .home-sub2,.product,.real-ser {
    padding: 0 !important;
    min-height: 440px;
    position: relative;
    overflow: hidden;
}
/* #home{
    height: 70vh;
} */
.product{
    min-height: 316px;
}
.real-ser{
    background-color: rgba(253,253,253,.3);
    background-image: linear-gradient(360deg,rgba(193,218,255,0.3) 0%,rgba(253,253,253,0.3) 80%);
    padding-bottom: 3% !important;
}
.real-ser-title-content{
    margin-top: 16px;
    text-align: center;
}
.home-news{
    height: 100%;
    position: relative;
    margin: auto;
    will-change: transform;
    flex-direction: column;
}
.banner-user{
    height: 95vh;
    position: relative;
}
.title-wrapper {
    margin: 0 auto 5px;
    text-align: center;
}
.pro-ser-title{
    text-align: center;
}
.title-wrapper>h1, .home-page-wrapper>h1 {
    font-size: 1.5rem;
    color: #314659;
    margin-bottom: 0;
}
.home-news-body-title {
    font-size: 1rem;
    font-weight: 600;
}

.home-news-body-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 0.9rem;
    color: #999;
}
.server-body-text{
    font-size: 0.9rem;
    color: #999;
}
.product-text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-size: 1rem;
}
.home-news-footer {
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important;
}
.home-div-product{
    margin-top: 10px;
}
.home-card-hover {
    box-shadow: none;
	position: relative;
	background-color: transparent;
	transition: 0.5s;
	overflow: hidden;
	border-radius: 5px;
    color: #fff;
}

.home-card-hover:hover {
    background: #fff;
	color: #000;
	box-shadow: 0 0px 32px rgba(6, 13, 15, 0.35) !important;
	transition-delay: 0.5s;
}
.home-card-hover:hover small{
    color: #000 !important;
}
.home-card-hover::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	border-top: 2px solid #84bdfd;
	border-left: 2px solid #6eacfc;
	transition: 0.5s;
	transition-delay: 0.5s;
}

.home-card-hover:hover::before {
	width: 100%;
	height: 100%;
	transition-delay: 0s;
}

.home-card-hover::after {
	content: '';
	position: absolute;
	right: 0;
	bottom: 0;
	width: 20px;
	height: 20px;
	border-bottom: 2px solid #a593f5;
	border-right: 2px solid #9f86fa;
	transition: 0.5s;
	transition-delay: 0.5s;
}

.home-card-hover:hover::after {
	width: 100%;
	height: 100%;
	transition-delay: 0s;
}
.pro-card-hover{
    box-shadow: none !important;
    transition: All 0.4s ease-in-out;
    -webkit-transition: All 0.4s ease-in-out;
    -moz-transition: All 0.4s ease-in-out;
    -o-transition: All 0.4s ease-in-out;
    background-color: transparent;
    color: #bdbdbd;
}
.pro-card-hover:hover{
    box-shadow: 0 0px 32px rgba(6, 13, 15, 0.35) !important;
    transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
}
.home-news-footer-date {
    padding-top: 0.4rem;
    color: #c2c2c2;
    margin: 0.2rem;
    margin-top: 0;
    margin-bottom: 0;
}

.banner-user {
    width: 100%;
    height: 100vh;
    position: relative;
    text-align: center;
    border-color: #666;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

.banner-user-elem {
    text-align: center;
    color: #fff;
    position: relative;
    overflow: hidden;
}

.banner-user-elem .banner-user-title {
    font-size: 32px;
    top: 40%;
}

.banner-user-elem .banner-user-text {
    top: 40%;
}

.banner-anim-elem .bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
} 
.banner-wrapper {
    display: inline-block !important;
    position: absolute !important;
    top: 35% !important;
    margin: auto !important;
    left: 0 !important;
    right: 0 !important;
    font-size: 14px !important;
    color: #e0e0e0;
    width: 550px;
}
.banner-title {
    width: auto;
    min-width: 350px;
    /* left: 30px; */
    min-height: 60px;
    margin: auto;
    display: inline-block;
    position: relative;
}
.banner-title>h2{
    font-size: 3rem;
    letter-spacing: 5px;
}
.banner-content {
    margin-bottom: 20px;
    margin-top: 20px;
    word-wrap: break-word;
    min-height: 24px
}
.banner-content>span{
    font-size: 2rem;
    font-weight: normal;
    letter-spacing: 5px;
}
.banner-button {
    border: 1px solid #fff;
    color: #fff;
    background: 0 0;
    box-shadow: 0 0 0 transparent;
    font-size: 16px;
    height: 40px;
    transition: background .45s cubic-bezier(.215,.61,.355,1),box-shadow .45s cubic-bezier(.215,.61,.355,1)
}
.banner-icon{
    bottom: 20px;
    font-size: 24px;
    position: absolute !important;
    left: 50%;
    margin-left: -12px;
    color: #fff
}
.ratio-1x1{
    height: 100%;
}
.home-card-body{
    padding-top: 10px;
}
.home-card-dz{
    text-align: center;
    background: transparent;
    color: #bdbdbd;
}
.home-card-dz-text{
    text-align: left;
}
.MessageList .left{
    margin-left: 0 !important;
}

@media screen and (max-width: 767px){
    #qpai {
        height: 360px !important;
    }
    .banner-title {
        width: 90%;
        left: 0;
    }
    .banner-wrapper{
        width: 90%;
    }
    .server-body-text{
        font-size: 1.2rem;
    }
    .server-title-text{
        font-size: 1.3rem;
    }
    /* #home{
        height: 90vh;
    } */
}