.page1 {
    min-height: 600px;
    position: relative;
    overflow: hidden;
}

.product-good-title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
}

.product-good-title-wrapper {
    margin: 0 auto 48px
}

.product-good-title-wrapper>h1 {
    font-size: 1.6rem;
    color: #314659;
}

.lead {
    font-size: 1rem;
    color: #424242;
}

.show-img-center {
    justify-content: center;
}

.product-good-img {
    padding: 40px;
}

.product-good-title {
    font-size: 1.2rem;
    color: #455a64;
}

.product-good-text {
    font-size: 0.9rem;
    text-align: center;
    color: #607d8b;
}

.what-ai {
    padding: 0 !important;
    min-height: 470px;
    position: relative;
    overflow: hidden;
    background-color: #e3f2fd;
}

.livevh-container {
    height: 100%;
    position: relative;
    margin: auto;
    will-change: transform;
    flex-direction: column;
}

.livevh-banner-img {
    width: 100%;
    height: 385px;
    min-height: 210px;
}

.swiper {
    width: 100%;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px !important;
    height: 300px !important;
}

.swiper-slide img {
    display: block;
    width: 100%;
}
.use-platform{
    padding: 0 !important;
    min-height: 430px;
    position: relative;
    overflow: hidden;
}
.use-platform-card{
    border-radius: 20px;
}
.use-platform-div{
    flex-direction: column;
}
.use-platform-img{
    border-radius: 30px;
}
.use-now{
    display: block;
}
@media all and (max-width: 768px) {
    .livevh-banner-img {
        height: 210px;
    }
    .use-platform-img{
        height: 100px;
        width: 100px;
    }
    .use-now{
        display: none;
    }
    .product-good-img{
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
    }
}