.footer{
    padding: 0 !important;
    min-height: 440px;
    position: relative;
    overflow: hidden;
}
.diy-footer{
    background-color: #2f363c;
    color: #e2e2e2 !important;
}
.diy-footer-col>p,.diy-footer-div>p,.diy-footer-col p>a{
    margin-bottom: 0.1rem;
    color: #bdbdbd !important;
    font-size: 0.9rem;
}
.diy-footer-company-des{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #bdbdbd !important;
    font-size: 0.9rem;
}
.qr-tips-text{
    color: #bdbdbd !important;
    font-size: 0.9rem;
}
.foot{
    padding: 0 !important;
    min-height: 280px;
    position: relative;
    overflow: hidden;
}
.text-center{
    color: #bdbdbd !important;
}
.foot-icp{
    font-size: 0.8rem;
}
.foot-icp a:hover{
    color: #ede7f6 !important;
}
@media screen and (max-width: 767px){
    .diy-text-left {
        text-align: left !important;
    }
}