.agency-support-title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
}

.agency-support-title-wrapper {
    margin: 0 auto 48px
}

.agency-support-title-wrapper>h1 {
    font-size: 1.6rem;
    color: #314659;
}
.support-col{
    text-align: center;
    padding-top: 0 !important;
    padding-bottom: 1.5rem !important;
}
.support-card{
    border-radius: 30px;
}
.support-card-img{
    padding: 5rem !important;
    padding-top: 1rem !important;
    padding-bottom: 0 !important;
}
.support-card-title{
    color: #263238;
    font-size: 1.2rem;
}
.support-card-text{
    color: #455a64;
    font-size: 0.9rem;
}
.work-flow{
    padding: 0 !important;
    min-height: 320px;
    position: relative;
    overflow: hidden;
}
.work-flow-card{
    text-align: center;
    border-radius: 60px;
}
.work-flow-card-img{
    padding: 4rem !important;
    padding-bottom: 0 !important;
    padding-top: 1rem !important;
    border-radius: 0;
}
@media screen and (max-width: 767px){
    .work-flow-card-img{
        padding: 3.5rem !important;
        padding-bottom: 0 !important;
        padding-top: 1rem !important;
        border-radius: 0;
    }
}