.ivl-banner-img{
    height: 100vh;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}
#whativl{
    background-size: cover;
    background-repeat: no-repeat;
}
.ivl-row-video{
    position: relative;
    top: 20%;
    display: flex;
    padding: 3rem;
}
.ivl-row-col-card{
    background: transparent;
    color: #fff;
    text-align: left;
}
.ivl-row-col-card>div{
    text-align: left;
}
.ivl-row-col-card-title{
    font-size: 2.7rem;
    font-weight: bold;
    color: #e0e0e0;
}
.ivl-row-col-card-text>li{
    font-size: 1.5rem;
}
.ivl-video-1{
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    object-fit: cover;
    box-sizing: border-box;
    padding: 10px;
    background: #bdbdbd;
    border-radius: 10px;
}
.ivl-introduce{
    text-align: center;
    background: transparent;
}
.ivl-page-base{
    min-height: 250px;
    width: 100%;
    background-image: linear-gradient(to top, #c4d5df, #cfd8dc);
}
.ivl-page2{
    min-height: 500px;
    width: 100%;
    background-image: linear-gradient(to top, #c4d5df, #cfd8dc);
}
/* .ivl-page3{
    min-height: 500px;
    width: 100%;
    background-image: linear-gradient(to top, #cfd8dc,#c4d5df);
} */
.ivl-page2-col{
    margin-top: 1rem !important;
}
.ivl-page2-card-title{
    font-size: 2rem;
    font-weight: 500;
}
.ivl-page2-card-text{
    font-size: 1.2rem;
    text-align: left;
}
.ivl-page2-ai{
    background: transparent;
}
.ivl-download{
    font-size: 1.4rem;
}
@media screen and (max-width: 767px){
    .ivl-row-video{
        padding: 0;
        top: 20%;
    }
    .ivl-row-video>div{
        margin: 0 !important;
    }
    .ivl-row-col-card-title{
        font-size: 2.5rem;
        text-align: left;
    }
    .ivl-row-col-card>div{
        text-align: center !important;
    }
    .ivl-row-col-card-text{
        text-align: left;
    }
    .ivl-page2-col-card{
        margin: 0 !important;
        padding: 0 !important;
    }
}